import React, {Component, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Navigation from "./components/navigation/navigation";
import Home from './pages/home/home';
import About from "./pages/about/about";
import Privacy from "./pages/privacy/privacy";
import Eula from "./pages/eula/eula";
import './index.css';
import SocialNetwork from "./components/social-network/social-network";
import Blog from "./pages/blog/blog";
import Consumption from "./pages/blog/consumption";
import VisitorCount from "./js/visitor-count";
import PuzzlesComplexity from "./pages/blog/puzzles-complexity";

const Admin = React.lazy(() => import('./pages/admin/admin'))

class Index extends Component {

    constructor(props) {
        super(props);

        let date = new Date();
        this.currentYear = date.getFullYear();
    }

    render() {
        return (
            <BrowserRouter>
                <div className="page">
                    <header>
                        <Navigation/>
                    </header>
                    <div className="content">
                        <VisitorCount />
                        <Suspense fallback={<div>Loading...</div>}>
                            <Routes>
                                <Route exact path='/' element={<Home/>}/>
                                <Route exact path='/blog' element={<Blog/>}/>
                                <Route exact path='/blog/consumption' element={<Consumption/>}/>
                                <Route exact path='/blog/puzzles-complexity' element={<PuzzlesComplexity/>}/>
                                <Route exact path='/about' element={<About/>}/>
                                <Route exact path='/privacy' element={<Privacy/>}/>
                                <Route exact path='/eula' element={<Eula/>}/>
                                <Route exact path='/admin' element={<Admin/>}/>
                            </Routes>
                        </Suspense>
                    </div>
                    <footer>
                        <br/>
                        <div className="soc-container">
                            <SocialNetwork logoSize={48} label="Social networks:"/>
                        </div>
                        <div className="company-container">
                            <p>&copy; {this.currentYear}, Deer Vision | <a className={"text-link"} href={"/privacy"} title={"Privacy Policy"}>Privacy</a> | <a className={"text-link"} href={"/eula"} title={"End User License Agreement"}>EULA</a></p>
                            <p>Company number: BE 0794.357.843</p>
                            <p>Contact: <a className={"text-link"} href={"mailto:deervision@proton.me"}>deervision@proton.me</a></p>
                        </div>
                    </footer>
                </div>
            </BrowserRouter>
        );
    }
}

ReactDOM
    .createRoot(document.getElementById('app'))
    .render(<Index/>)
